import React from 'react';
import { Link } from 'react-router-dom';
import { MdInfoOutline } from 'react-icons/md';

const Product = ({ title, description, image, imageAlt, id }) => {
    console.log('id',id);
    return (
        <div className="product with_padding">
            <div className="inner_product">
                <img src={image} alt={imageAlt} className="product_image image_resp" />
                <div className="banner_desc">
                    <Link to={`/product/${id}`} className="poppins bannerTitle">Discover more  <MdInfoOutline className="more_info"/> </Link>
                </div>
            </div>
            <div className="description">
                <h3 className="product_title poppins">{title}</h3>
                <p className="product_description opSans">{description}</p>
            </div>
        </div>
    );
}

export default Product;