import React from 'react';
import ContattiHeader from './ContattiHeader';
import ContattiMap from './ContattiMap';
import ContattiForm from './ContattiForm';
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import {Helmet} from "react-helmet";

const options = {
    timeout: 5000,
    position: positions.MIDDLE,
}

const Contatti = () => {
    return (
        <>
            <Provider template={AlertTemplate} {...options}>
                <ContattiHeader />
                <div className="container" style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="keywords" content="contatti contattaci apicoltura-costenaro  vertemate-con-minoprio vertemate con minoprio costenaro apicoltura giancarlo costenaro api contact-us find-us honey-dealer  " />
                        <meta name = "description" content="We have been producing and selling honey for decades. Quality products and 100% local. Contact us!" />
                        <title>Contact us | Apicoltura Costenaro</title>
                    </Helmet>
                    <ContattiMap />
                    <ContattiForm />
                </div>
            </Provider>            
        </>
    );
}

export default Contatti;