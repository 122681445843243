import React from 'react';
import Button from './reusable/Button';
import staff from '../assets/chi_siamo_images/staff_verde4.jpg';

const WeAreBlock = props => {
    return (
        <div className="weare">
            <div className="leftImage">
                <img alt="staff_costenaro" src={staff} className="weare_img img_resp" />
            </div>
            <div className="right_desctiption">
                <h3 className="opSans">About us</h3>
                <p className="opSans" style={{marginBottom: '2.5rem'}}>
                    We are a family of beekeepers, that gathers 3 different generations. We are committed to safeguarding one of the most important living being on the earth, the bee. Our continuous research for areas with low industrial impact and high biodiversity level, allow us to produce excellent quality honeys and bring them to the table of our customers at the most competitive price possible...
                </p>
                <Button to="/about_us" text="read more" />
            </div>
        </div> 
    );
}

export default WeAreBlock;