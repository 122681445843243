import React from 'react';
import {Helmet} from "react-helmet"
import a from '../../assets/chi_siamo_images/1-min.jpg'
import b from '../../assets/chi_siamo_images/2-min.jpg'
import c from '../../assets/chi_siamo_images/3-min.jpg'
import e from '../../assets/chi_siamo_images/5-min.jpg'
import f from '../../assets/chi_siamo_images/6-min.jpg'
import g from '../../assets/chi_siamo_images/7-min.jpg'
import h from '../../assets/chi_siamo_images/8.jpg'

const ChiSiamo = () => {
    const pics = [
        {
            src: g,
            nome: "Arrigo",
        },
        {
            src: c,
            nome: "Anna",
        },
        /*{
            src: b,
            nome: "Giancarlo",
        },*/
        {
            src: e,
            nome: "Mauro",
        },
        {
            src: f,
            nome: "Elena",
        },
        {
            src: a,
            nome: "Luca",
        },
        {
            src: h,
            nome: "Elia",
        },
    ]
    return (

        <div className="container" style={{paddingTop: '100px', paddingLeft: '15px', paddingRight: '15px'}}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="chi siamo apicoltura api miele como staff vertemate con minoprio costenaro apicoltura giancarlo costenaro about-us ahbout honey bee bees local eco friendly non-industrial" />
                <meta name = "description" content="We have been producing and selling honey for decades. Quality products and 100% local. Find out who we are!" />
                <title>About us | Apicoltura Costenaro</title>
            </Helmet>
            <div className="upperTitle" style={{paddingTop: '50px'}}>
                <h2 className="poppins chisiamo_title">Here we are!!</h2>
                <p className="chisiamo_short_desc opSans">
                    <strong>Beekeepers from decades</strong> <br />
                   Why teh best? Cause we love what we do.
                </p>
                <div className="descr">
                    <img src={b} alt="" />
                    <p className="opSans">
                        Hi, my name is Giancarlo Costenaro. I'm from Vertemate con Minoprio, a small town located between the pre-alpine zone and the beginning of the Po Valley.
                        I'm the co-owner of the farm "Apicoltura Costenaro G&C SNC". It is a family-run business, which involves even three different generations! Currently, my sons and I are working with my parents, benefiting from their decennial experience started in 1974, to draw inspiration for the future.
                        My parents begun this adventure when I was 8. I still remember the first time I saw a beehive.. how scared I was! I was frightened by this new reality, but I learned quickly how to face it and I started to appreciate it in all its shades. The best occurred when I tasted for the first time the honey we collected, I've never been so fulfilled. So, long story short, I really got into bees. I was amazed at how a so small creature lived a so busy life and play a crucial and specific role within its society. I spent hours staring at them, enjoying their accurate and coordinated flight, always aimed to find the nearest pollen and nectar sources.
                        Growing up, as self-taught, I studied the most common methods used in the beekeeping sector, the beekeeping-related flora, and some herbalism. When I was 22, I founded my business, turning a small reality in a real farm, involving all my relatives.
                        Nowadays, we take care of more than one thousand beehives, which provide us honey, pollen, royal jelly, propolis, and other herbal by-products. We take our bees to pristine lands, far from urban centers, in order to guarantee them a peaceful life in their habitat. This wellness condition allows them to produce high-quality honey.
                        Because our aim is to bring to our clients' home the best products which bees can give to us, fresh and genuine. So that opening our jars, you can immerse yourself in the scents and in the taste of the untouched spaces where the bees live!
                    </p>
                </div>
            </div>
            <div className="chisiamo_fotodiv container">
                {
                    pics.map((el, i) => (
                        <div className="person_container" key={`image-${i}`}>
                            <img src={el.src} className="chisiamo_image" alt="" />
                            <div className="chisiamo_caption">
                                <h6 className="poppins">{el.nome}</h6>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ChiSiamo;