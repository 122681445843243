import ac1 from '../../assets/products_images/acacia/miele_acacia_1kg_bianco.jpg';
import ac2 from '../../assets/products_images/acacia/miele_acacia_1kg.jpg';
import ac3 from '../../assets/products_images/acacia/miele_acacia_1kg_bianco_valori_nutrizionali.jpg';
import ac4 from '../../assets/products_images/acacia/miele_acacia_250g.jpg';
import ac5 from '../../assets/products_images/acacia/miele_acacia_250g_bianco.jpg';
import ac6 from '../../assets/products_images/acacia/miele_acacia_250g_bianco_valori_nutrizionali.jpg';
import ac7 from '../../assets/products_images/acacia/miele_acacia_500g.jpg';
import ac8 from '../../assets/products_images/acacia/miele_acacia_500g_bianco.jpg';
import ac9 from '../../assets/products_images/acacia/miele_acacia_500g_bianco_valori_nutrizionali.jpg';
import ac10 from '../../assets/products_images/acacia/miele_acacia_mignon.jpg';
import ca1 from '../../assets/products_images/castagno/miele_castagno_1kg_bianco.jpg';
import ca2 from '../../assets/products_images/castagno/miele_castagno_1kg.jpg';
import ca3 from '../../assets/products_images/castagno/miele_castagno_1kg_bianco_valori_nutrizionali.jpg';
import ca4 from '../../assets/products_images/castagno/miele_castagno_250g.jpg';
import ca5 from '../../assets/products_images/castagno/miele_castagno_250g_bianco.jpg';
import ca6 from '../../assets/products_images/castagno/miele_castagno_250g_bianco_valori_nutrizionali.jpg';
import ca7 from '../../assets/products_images/castagno/miele_castagno_500g.jpg';
import ca8 from '../../assets/products_images/castagno/miele_castagno_500g_bianco.jpg';
import ca9 from '../../assets/products_images/castagno/miele_castagno_500g_bianco_valori_nutrizionali.jpg';
import ar1 from '../../assets/products_images/arancio/miele_arancio_1kg.jpg';
import ar2 from '../../assets/products_images/arancio/miele_arancio_500g.jpg';
import ci1 from '../../assets/products_images/cicliegio/miele_ciliegio_500g.jpg';
import eu1 from '../../assets/products_images/eucalipto/miele_eucalipto_1kg.jpg';
import gi1 from '../../assets/products_images/girasole/miele_girasole_1kg.jpg';
import gi2 from '../../assets/products_images/girasole/miele_girasole_1kg_bianco.jpg';
import gi3 from '../../assets/products_images/girasole/miele_girasole_1kg_bianco_valori_nutrizionali.jpg';
import gi4 from '../../assets/products_images/girasole/miele_girasole_500g.jpg';
import gi5 from '../../assets/products_images/girasole/miele_girasole_500g_bianco.jpg';
import gi6 from '../../assets/products_images/girasole/miele_girasole_500g_bianco_valori_nutrizionali.jpg';
import me1 from '../../assets/products_images/melata/miele_melata_1kg.jpg';
import me2 from '../../assets/products_images/melata/miele_melata_1kg_bianco.jpg';
import me3 from '../../assets/products_images/melata/miele_melata_1kg_bianco_valori_nutrizionali.jpg';
import me4 from '../../assets/products_images/melata/miele_melata_250g.jpg';
import me5 from '../../assets/products_images/melata/miele_melata_250g_bianco.jpg';
import me6 from '../../assets/products_images/melata/miele_melata_250g_bianco_valori_nutrizionali.jpg';
import me7 from '../../assets/products_images/melata/miele_melata_500g.jpg';
import me8 from '../../assets/products_images/melata/miele_melata_500g_bianco.jpg';
import me9 from '../../assets/products_images/melata/miele_melata_500g_bianco_valori_nutrizionali.jpg';
import mi1 from '../../assets/products_images/millefiori/miele_millefiori_1kg.jpg';
import mi2 from '../../assets/products_images/millefiori/miele_millefiori_1kg_bianco.jpg';
import mi3 from '../../assets/products_images/millefiori/miele_millefiori_1kg_bianco_valori_nutrizionali.jpg';
import mi4 from '../../assets/products_images/millefiori/miele_millefiori_250g.jpg';
import mi5 from '../../assets/products_images/millefiori/miele_millefiori_250g_bianco.jpg';
import mi6 from '../../assets/products_images/millefiori/miele_millefiori_250g_bianco_valori_nutrizionali.jpg';
import mi7 from '../../assets/products_images/millefiori/miele_millefiori_500g.jpg';
import mi8 from '../../assets/products_images/millefiori/miele_millefiori_500g_bianco.jpg';
import mi9 from '../../assets/products_images/millefiori/miele_millefiori_500g_bianco_valori_nutrizionali.jpg';
import mi10 from '../../assets/products_images/millefiori/miele_millefiori_mignon.jpg';
import ti1 from '../../assets/products_images/tiglio/miele_tiglio_1kg.jpg';
import ti2 from '../../assets/products_images/tiglio/miele_tiglio_1kg_bianco.jpg';
import ti3 from '../../assets/products_images/tiglio/miele_tiglio_250g.jpg';
import ti4 from '../../assets/products_images/tiglio/miele_tiglio_250g_bianco.jpg';
import ti5 from '../../assets/products_images/tiglio/miele_tiglio_250g_bianco_valori_nutrizionali.jpg';
import ti6 from '../../assets/products_images/tiglio/miele_tiglio_500g.jpg';
import ti7 from '../../assets/products_images/tiglio/miele_tiglio_500g_bianco.jpg';
import ti8 from '../../assets/products_images/tiglio/miele_tiglio_500g_bianco_valori_nutrizionali.jpg';
import comp1 from '../../assets/products_images/composto_alveare/composto_alveare_250g.jpg';
import comp2 from '../../assets/products_images/composto_alveare/composto_alveare_500g.jpg';
import pr1 from '../../assets/products_images/propolis/propolis_1.jpg';
import pr2 from '../../assets/products_images/propolis/propolis_2.jpg';
import pr3 from '../../assets/products_images/propolis/propolis_bianco.jpg';
import pr4 from '../../assets/products_images/propolis/propolis_con_confezione_bianco.jpg';
import pr5 from '../../assets/products_images/propolis/propolis_confezione_bianco.jpg';
import pr6 from '../../assets/products_images/propolis/propolis_soluzione_idroglicerica.jpg';
import pr7 from '../../assets/products_images/propolis/propolis_valori_nutrizionali.jpg';
import po1 from '../../assets/products_images/polline/polline_100g.jpg';
import po2 from '../../assets/products_images/polline/polline.jpg';
import po3 from '../../assets/products_images/polline/polline_100g_bianco.jpg';
import po4 from '../../assets/products_images/polline/polline_250g.jpg';
import po5 from '../../assets/products_images/polline/polline_250g_bianco.jpg';
import po6 from '../../assets/products_images/polline/polline_250g_bianco_con_demo.jpg';
import pappa1 from '../../assets/products_images/pappa_reale/pappa_reale1.jpg';
import pappa2 from '../../assets/products_images/pappa_reale/pappa_reale2.jpg';
import pappa3 from '../../assets/products_images/pappa_reale/pappa_reale_con_confezione.jpg';
import pappa4 from '../../assets/products_images/pappa_reale/pappa_reale_con_confezione2.jpg';
import pappa5 from '../../assets/products_images/pappa_reale/pappa_reale_confezione.jpg';
import cera1 from '../../assets/products_images/cera/cera_api_1kg_bianco.jpg';
import mn1 from '../../assets/products_images/miele_nocciole/miele_con_nocciole2_227g.jpg';
import mn2 from '../../assets/products_images/miele_nocciole/miele_con_nocciole2_423g.jpg';
import mn3 from '../../assets/products_images/miele_nocciole/miele_con_nocciole_227g.jpg';
import mn4 from '../../assets/products_images/miele_nocciole/miele_con_nocciole_423g.jpg';
import noci1 from '../../assets/products_images/miele_noci/miele_con_noci_443g.jpg';
import bal1 from '../../assets/products_images/sciroppo/sciroppo_balsamel1.jpg';
import bal2 from '../../assets/products_images/sciroppo/sciroppo_balsamel2.jpg';
import bal3 from '../../assets/products_images/sciroppo/sciroppo_balsamel_bianco.jpg';
import bal4 from '../../assets/products_images/sciroppo/sciroppo_balsamel_bianco_2.jpg';
import lic1 from '../../assets/products_images/sciroppo/sciroppo_lichene.jpg';
import lic2 from '../../assets/products_images/sciroppo/sciroppo_lichene_bianco.jpg';










export const prods = [
    {
        category: 'Honey',
        prods: [
            {
                title: 'Acacia honey',
                description: 'The nectar, which is transformed by bees into honey, comes in different colors, varying from a crystalline shade up to a straw yellow hue. The difference in color is mainly due to the presence of nectar from other blooms, which typically flourish together such as cherry or hawthorn. ',
                image: ac2,
                imageAlt: 'miele_acacia_1kg_bianco',
                id: "acacia-honey",
            },
            {
                title: 'Chestnut honey',
                description: 'The healing properties of its substances have been recognized since ancient times, and recent university studies have found that it is an excellent antibacterial against Helicobacter, Escherichia coli, and staphylococcus. It is also used in the treatment and prevention of ulcers, it acts on infections and speeds healing process. ',
                image: ca2,
                imageAlt: 'miele_castagno_1kg_bianco',
                id: 'chestnut-honey',
            },
            {
                title: 'Wildflower honey',
                description: 'This type of honey, given its low glucose content, is a really good source of energy and, taken for breakfast, it will give you the energy you need to face any challenge it will show up to you during the rest of the day.',
                image: mi1,
                imageAlt: 'miele_millefiori_1kg',
                id: "wildflower-honey",
            },
            {
                title: 'Linden honey',
                description: 'This honey has a light amber color with yellow-green reflections that indicate its purity. Its taste is intense and sweet, which will leave a feeling of freshness in your mouth. In popular tradition, this type of honey is believed to have antispasmodic properties that act on the nervous system as a sedative. Due to this feature, it is recommended to conciliate sleep and reduce anxiety.',
                image: ti1,
                imageAlt: 'miele_tiglio_1kg',
                id: "linden-honey",
            },
            {
                title: 'Honeydew honey',
                description: 'The honey produced has a less sweet taste due to the presence of a lower quantity of sugar than in the other types of honey. It has an intense dark brown color given by the presence of many antioxidants.',
                image: me1,
                imageAlt: 'miele_melata_1kg_bianco',
                id: "honeydew-honey",
            },
            {
                title: 'Cherry honey',
                description: 'One of the main functioning of cherry honey is the detoxification of the liver and kidneys, thanks to a diuretic action. In addition, it exerts an antioxidant function, protecting our body from the adverse action of free radicals and premature ageing.',
                image: ci1,
                imageAlt: 'miele_ciliegio_500g',
                id: "cherry-honey",
            },
            {
                title: 'Eucalyptus honey',
                description: 'Eucalyptus honey is amber-colored and has a rather compact and dense consistency. It tends to crystallize rapidly, over a few months, and once crystallized its color turns to greyish-beige. The taste of this honey is strong, intense, balsamic and not too sweet. It has a very peculiar smell, due to its balsamic characteristics.',
                image: eu1,
                imageAlt: 'miele_eucalipto_1kg',
                id: "eucalyptus-honey",
            },
            {
                title: 'Sunflower honey',
                description: ' Liquid sunflower honey has a golden yellow color, reminiscent of the flower from which it derives. This honey crystallizes rapidly turning into a dark yellow color. It has a delicate scent that recalls wax and pollen. It has a sweet and refreshing taste and once crystallized it gets more fruity and also an aftertaste of star anise.',
                image: gi1,
                imageAlt: 'miele_girasole_1kg_bianco',
                id: "sunflower-honey",
            },
            {
                title: 'Orange honey',
                description: 'This honey is an excellent sweetener and it is used in the most different recipes, from desserts ( especially for those based on cream ) to savory dishes, but also for drinks and herbal teas. It can be paired with various cheeses such as mozzarella, scamorza, and caciocavallo.',
                image: ar1,
                imageAlt: 'miele_arancio_1kg',
                id: "orange-honey",
            },

        ]
    },
    {
        category: 'Beehive derivatives',
        prods: [
            {
                title: 'Beehive compound',
                description: 'The hive compound is suitable for growing children, for adults before or after intense physical activity or works, during seasonal changes, for those studying, for tiredness or after a period of illness.',
                image: comp2,
                imageAlt: 'composto_alveare',
                id: "beehive-compound",
            },
            {
                title: 'Propolis',
                description: 'Propolis is produced by the combination of substances such as resins, balms collected by bees in the buds of some plants, later enriched by salivary secretions full of enzymes, aromatic acids, wax, and pollen. It is also rich in flavonoids and polyphenols, and a high number of mineral salts including iron, calcium, copper, and manganese.',
                image: pr1,
                imageAlt: 'propolis_propoli',
                id: "propolis",
            },
            {
                title: 'Pollen',
                description: 'Pollen is one of the richest substances of nature, it contains a wide range of different elements that are essential for a balanced diet. It consists partly of royal jelly and, therefore, is richly energetic and retains all the properties of royal jelly.',
                image:po1,
                imageAlt: 'polline',
                id: "pollen",
            },
            {
                title: 'Royal jelly',
                description: 'It is recommended to use it to enrich the diet of elderly people, to balance an adult\'s diet and to strengthen the organism during seasonal changes. It is a precious natural supplement for children especially in cases of lack of appetite.',
                image: pappa1,
                imageAlt: 'pappa_reale',
                id: "royal-jelly",
            },
            {
                title: 'Beeswax',
                description: 'Pure beeswax is usually used to create preparations for face, body and hand creams and in general cosmetic and pharmaceutical products, but also by carpenters to polish materials and products or to create candles.',
                image: cera1,
                imageAlt: 'cera_api',
                id: "beeswax",
            },
        ]
    },
    {
        category: 'Health and wellness',
        prods: [
            {
                title: 'Honey with hazelnuts',
                description: 'Considered a delicacy of colder periods, the combination of these two products has important effects on health, for instance on blood circulation.',
                image: mn1,
                imageAlt: 'miele_con_nocciole',
                id: "honey-with-hazelnuts",
            },
            {
                title: 'Honey with walnuts',
                description: 'Considered a delicacy of colder periods, the combination of these two products has important effects on health, for instance on blood circulation.',
                image: noci1,
                imageAlt: 'miele_noci',
                id: "honey-with-walnuts",
            },
            {
                title: 'Balsamel syrup',
                description: 'Rich in mineral salts, this product is particularly suitable in the winter months, as it helps to both prevent and soothe the effects of seasonal illnesses such as cold and cough.',
                image: bal1,
                imageAlt: 'sciroppo_balsamel',
                id: "balsamel-syrup",
            },
            {
                title: 'Iceland lichen syrup',
                description: 'This product boasts bactericidal, antibiotic, emollient and protective properties of the respiratory and gastrointestinal system: it is in fact indicated for respiratory disorders especially in the case of oily cough.',
                image: lic1,
                imageAlt: 'sciroppo_lichene_propoli',
                id: "iceland-lichen-syrup",
            },
        ]
    },
];

export const singles = [
    {
        id: "acacia-honey" ,
        pictures: [
            ac1,
            ac2,
            ac3,
            ac4,
            ac5,
            ac6,
            ac7,
            ac8,
            ac9,
            ac10,
        ],
        title: 'Acacia honey',
        description: 'The nectar, which is transformed by bees into honey, comes in different colors, varying from a crystalline shade up to a straw yellow hue. The difference in color is mainly due to the presence of nectar from other blooms, which typically flourish together such as cherry or hawthorn. ',
        longDescription: 'Bees collect the precious and delicate nectar from the flowers of Robina Pseudoacacia, imported from North America in 1600 and quickly spread throughout Europe. Acacia is a plant appreciated both by lumberjacks, due to its speed of growth and calorific value (especially in winter), and by beekeepers, for its abundant flowering and ability to constantly release nectar. The blossoming of Robina occurs around May and unfortunately in recent years, for beekeepers, the advent of this event is accompanied by bad weather and rain.\n' +
            'The nectar, which is transformed by bees into honey, comes in different colors, varying from a crystalline shade up to a straw yellow hue. The difference in color is mainly due to the presence of nectar from other blooms, which typically flourish together such as cherry or hawthorn. \n' +
            'Being very poor in enzymes, mineral salts, and acidity, its extremely delicate taste is usually preferred by children and aged. Due to its high fructose content, it is recommended as a sweetener in diets for people suffering from diabetes and for sportspeople as well, thanks to its relevant and fast availability of energy.\n' +
            'Our honey is totally bio and it\'s not processed with thermal treatments, to preserve its organoleptic characteristics and its natural taste.',
        formats: ['mignon','250g','500g','1kg'],
    },
    {
        id: 'chestnut-honey',
        pictures: [
            ca1,
            ca2,
            ca3,
            ca4,
            ca5,
            ca6,
            ca7,
            ca8,
            ca9,
        ],
        title: 'Chestnut honey',
        description: 'The healing properties of its substances have been recognized since ancient times, and recent university studies have found that it is an excellent antibacterial against Helicobacter, Escherichia coli, and staphylococcus. It is also used in the treatment and prevention of ulcers, it acts on infections and speeds healing process. Chestnut honey has also antioxidant properties, which help fight the damage that can be induced by pollution and smoke. ',
        longDescription: 'Chestnut is an ancient native species of the Mediterranean territories, typical of Italian, French and Northern European forests. It appears as a mighty secular shrub, with the distinctive toothed-elongated leaves. This aspect has made him very frequent in the landscapes of myths and legends.\n' +
            'For centuries these plants played an important role in the cooking of our country: with its fruits, it has fed the population in times of famine and war, while its honey has been included in many recipes from the simplest to the most refined. The taste of this honey is sweet, with an intense bitter aftertaste which makes it unmistakable by the other honeys. For this peculiarity, it can be served with both sweet and savory foods. This characteristic is determined by tannin, a substance present in high concentrations in the plant with anti-inflammatory, antibacterial and hemostatic properties. Another feature of this product is the richness in nutrients such as proteins and mineral salts, which give it a unique dark amber color with amaranth red veins. Its color and flavor best express the autumn season, symbolizing the last spark of life before winter comes.\n' +
            'It is a natural product of the highest quality, which brings many benefits to health. The healing properties of its substances have been recognized since ancient times, and recent university studies have found that it is an excellent antibacterial against Helicobacter, Escherichia coli, and staphylococcus. It is also used in the treatment and prevention of ulcers, it acts on infections and speeds healing process. Chestnut honey has also antioxidant properties, which help fight the damage that can be induced by pollution and smoke. It also helps prevent and resolve problems and pains related to cardiovascular diseases, skin aging, and arthritis. Due to its notable moisturizing property, it is used in cosmetics for products dedicated to hair and skin. It contains vitamin B and C and mineral salts such as potassium, calcium, and iron.',
        formats: ['250g','500g','1kg'],
    },
    {
        id: "orange-honey",
        pictures: [
            ar1,
            ar2,
        ],
        title: 'Orange honey',
        description: 'This honey is an excellent sweetener and it is used in the most different recipes, from desserts ( especially for those based on cream ) to savory dishes, but also for drinks and herbal teas. It can be paired with various cheeses such as mozzarella, scamorza, and caciocavallo.',
        formats: ['250g','500g'],
        longDescription: 'Orange is a fruit tree, belonging to the Rutaceae family. It is typical of regions with a mild climate and is cultivated up to 600 meters above sea level. It is a plant that blooms in spring with very scented flowers. In Sardinia its white and delicate flower is commonly known as ‘zagara’, that means ‘the flower of the grooms’. \n' +
            ' This liquid honey is very light in color with different shades ranging from yellow to orange. After crystallization, instead, it turns into white or beige. It has a flowery, aromatic and delicate but also bitter smell that recalls the taste of the homonymous fruit. Its taste is very sweet, fresh and fruity with just a slightly acrid hint.\n' +
            ' This honey has different properties, among which a sedative action ( can be indicated to attenuate the symptoms of headache and insomnia ) and an antispasmodic one. It has a high content of vitamin B12 which has a beneficial impact on the nervous system because it helps fight fatigue, anxiety, and lack of appetite. It also contains many vitamins, mineral salts, enzymes, and amino acids. It is good against heartburn, especially when taken with hot teas. It also has antioxidant, anti-inflammatory and purging properties, especially if consumed before meals.\n' +
            ' This honey is an excellent sweetener and it is used in the most different recipes, from desserts ( especially for those based on cream ) to savory dishes, but also for drinks and herbal teas. It can be paired with various cheeses such as mozzarella, scamorza, and caciocavallo.',
    },
    {
        id: "cherry-honey",
        pictures: [
            ci1
        ],
        title: 'Cherry honey',
        description: 'One of the main functioning of cherry honey is the detoxification of the liver and kidneys, thanks to a diuretic action. In addition, it exerts an antioxidant function, protecting our body from the adverse action of free radicals and premature ageing.',
        formats: ['500g'],
        longDescription: 'Cherry trees are one of the first spring blooms fundamental, together with the dandelion, to the good recovery of the bees from the winter periods. Occasionally, due to the climate changes of the last decades, there are seasons in which, also in the hilly areas adjacent to Como, it is possible to produce this excellent honey.\n' +
            'Cherry honey has a slightly liquid consistency with a color that varies from straw yellow to a darker shade with reddish reflections.\n' +
            'The crystallization of this honey takes place slowly, resulting in a change of its color until it reaches a greyish-white shade with a fine grain and has a cherry-flavour with an aftertaste that recalls the taste of bitter almonds.\n' +
            'One of the main functioning of cherry honey is the detoxification of the liver and kidneys, thanks to a diuretic action. In addition, it exerts an antioxidant function, protecting our body from the adverse action of free radicals and premature ageing.\n' +
            'Furthermore, like all kinds of honey, it is a fast source of energy, useful to support the metabolic, vitamin and mineral salts needs of our body. Cherry honey can also be used as a cosmetic: in particular, it can be used as a beauty mask to restore brightness and shine to dull skin.',
    },
    {
        id: "eucalyptus-honey",
        pictures: [
            eu1
        ],
        title: 'Eucalyptus honey',
        description: 'Eucalyptus honey is amber-colored and has a rather compact and dense consistency. It tends to crystallize rapidly, over a few months, and once crystallized its color turns to greyish-beige. The taste of this honey is strong, intense, balsamic and not too sweet. It has a very peculiar smell, due to its balsamic characteristics.',
        formats: ['1kg'],
        longDescription: 'Eucalyptus is an evergreen tree plant native to Oceania and the Philippines. It was introduced in Italy in the \'700 with the wrong belief of purifying the lands from malaria: however, these plants, although without this ability, helped to reclaim the land by absorbing the stagnant water where mosquitoes, carriers of the disease, proliferated. Nowadays in Italy, eucalyptus plants are mostly found in the central-southern regions where these trees, which can reach up to 90 meters in height, have been able to proliferate thanks to the mild climate and the fact that in the various years lots of them were planted for the drainage of the swamps. The word Eucalyptus comes from the ancient Greek and means ‘well hidden’: this refers to the pistil that remains completely hidden by the petals until the moment of flowering.\n' +
            ' This plant has always been recognized as having beneficial properties. From its leaves an essential oil is produced, that has significant effects against cough, bacteria, and parasites. From its flowers instead, the bees produce very particular and distinctive honey between July and August.\n' +
            ' Eucalyptus honey is amber-colored and has a rather compact and dense consistency. It tends to crystallize rapidly, over a few months, and once crystallized its color turns to greyish-beige. The taste of this honey is strong, intense, balsamic and not too sweet. It has a very peculiar smell, due to its balsamic characteristics.\n' +
            ' Scientific studies have recognized that this honey is very rich in flavonoids ( whose content is very high in propolis as well ) which, in addition to being antioxidants ( good to reduce free radicals in the body ), play other important functions for our health among which we find: antiallergic properties, anti-inflammatory function, and antiviral qualities.\n' +
            ' Flavonoids, in particular, are extremely useful for preventing inflammatory and degenerative phenomena: thus making this honey a useful mean to counter cellular aging. Some studies have shown that eucalyptus honey produced in Italy, Spain, and Greece is much richer in these antioxidants; this is caused by the presence, in the honey, of propolis which has been also collected from the same trees. Having also healing properties, it can be used for small affections of the oral cavity and to treat intestinal and urinary tract disorders, such as cystitis.\n' +
            ' Its properties help to counteract and prevent the typical diseases of seasonal changes. It is also an excellent remedy for phlegm because it has a fluidifying and mucolytic action; it is used to counteract bronchitis and sinusitis too because it helps breathing by freeing the airways. To increase its effectiveness it is often taken with the homonymous concentrated essential oil, whose effectiveness has been recognized by several scientific studies.\n' +
            ' To benefit more from its properties, it is advisable to buy raw and not thermal-treated honey since the flavonoids, being bioactive substances, are very sensitive to high temperatures and light. It is advisable to keep the honey away from direct light and heat sources, during storage.\n' +
            ' Eucalyptus honey is also an ingredient in many recipes: it is often served with aged cheeses such as parmesan, but also with fresh and lean cheeses such as primo sale. It can also be used with first and second courses of fish and mixed vegetables, or it is often used in the preparation of sauces. It is dissolved in herbal teas and in tea, especially in the cold months of the year. It can also be paired with legumes and soups, to which it can give a special taste and even in snacks or sushi. Lastly, it can also be taken with yogurt or other fresh desserts.',
    },
    {
        id: "sunflower-honey",
        pictures: [
            gi1,
            gi2,
            gi3,
            gi4,
            gi5,
            gi6,
        ],
        title: 'Sunflower honey',
        description: 'Liquid sunflower honey has a golden yellow color, reminiscent of the flower from which it derives. This honey crystallizes rapidly turning into a dark yellow color. It has a delicate scent that recalls wax and pollen. It has a sweet and refreshing taste and once crystallized it gets more fruity and also an aftertaste of star anise.',
        formats: ['500g','1kg'],
        longDescription: 'Sunflower is a plant native to Peru, where is mostly cultivated to produce oil. In Italy, the largest crops are found in the regions of the center, particularly in Tuscany, Umbria, Lazio, and Marche. Not all flower varieties are nectareous and this has caused a decline in honey production. Fortunately, in recent years it has observed an inversion in this trend. The flowering period is from July to August.\n' +
            ' Liquid sunflower honey has a golden yellow color, reminiscent of the flower from which it derives. This honey crystallizes rapidly turning into a dark yellow color. It has a delicate scent that recalls wax and pollen. It has a sweet and refreshing taste and once crystallized it gets more fruity and also an aftertaste of star anise.\n' +
            ' The most known property of this honey is its ability to re-mineralize: in fact, this honey is a real mineral salt supplement, in particular, it is rich in calcium and magnesium, which help to prevent the weakening of the bones; for this reason, is indicated for the elderly and menopausal women. Although this honey is responsible for significant caloric intake, it can help control LDL cholesterol, the so-called "bad" cholesterol ( bad because it is related to the onset of cardiovascular diseases ) thanks to the presence of phytosterols. Clinical studies have observed that this honey brings many benefits if assumed instead of the daily sugar dose; with a balanced diet and with the necessary preventive measures he can contribute to the prevention of various cardiovascular diseases.\n' +
            ' It can be used as a sweetener in beverages or in the preparation of desserts, in particular in the production of dry biscuits. It is also used as an ingredient for some snacks and thanks to its protein content even in some slimming diets.',
    },
    {
        id: "honeydew-honey",
        pictures: [
            me1,
            me2,
            me3,
            me4,
            me5,
            me6,
            me7,
            me8,
            me9,
        ],
        title: 'Honeydew honey',
        description: 'The honey produced has a less sweet taste due to the presence of a lower quantity of sugar than in the other types of honey. It has an intense dark brown color given by the presence of many antioxidants.',
        formats: ['250g','500g','1kg'],
        longDescription: 'The bees produce this honey by collecting the sugary secretion of the Metcalfe Pruinosa, an unpopular aphid that feeds on plants sap. This secretion is a substance rich in sugars, mineral salts, and proteins that bees nourish of, during periods in which the blooms are already gone. The honey produced has a less sweet taste due to the presence of a lower quantity of sugar than in the other types of honey. It has an intense dark brown color given by the presence of many antioxidants. It is the honey with the highest concentration of mineral salts and trace elements such as calcium, magnesium, sodium, potassium, and iron; it also contains many essential vitamins and amino acids ( which have to be introduced with food since our body is not able to synthesize them ). Its components provide an antiseptic and antibacterial action. In the past, it was also used as a disinfectant for injuries. It is an excellent remedy to relieve sore throat and colds. Due to its characteristics, it is recommended to athletes as a supplement of mineral salts and for people who follow slimming diets. It is an energizer that also strengthens concentration and memory. In addition, probiotic bacteria are present which strengthen our intestines flora. We are particularly careful to its production because only the raw product retains all its properties; furthermore, in order not to run into the danger that heavy metals can be found inside the honey, our hives are positioned far from the industrialized zones.',
    },
    {
        id: "wildflower-honey",
        pictures: [
            mi1,
            mi2,
            mi3,
            mi4,
            mi5,
            mi6,
            mi7,
            mi8,
            mi9,
            mi10
        ],
        title: 'Wildflower honey',
        description: 'This type of honey, given its low glucose content, is a really good source of energy and, taken for breakfast, it will give you the energy you need to face any challenge it will show up to you during the rest of the day.',
        formats: ['mignon','250g','500g','1kg'],
        longDescription: 'This is one of the most widespread and popular kind of honey in Italy. As the name says, it contains nectar and pollen of countless flowers and it is always different in taste according to the season and harvest area. In our wildflower honey, you\'ll find nectars of flowers coming from the crops of the famous marins of Pavia, famous for floral biodiversity, but also from the untouched forests of our pre-alpine land.\n' +
            'This type of honey, given its low glucose content, is a really good source of energy and, taken for breakfast, it will give you the energy you need to face any challenge it will show up to you during the rest of the day.\n' +
            'It is also recommended as a detoxifier for the liver, in case of stress or to help digestion. It is particularly indicated for kids, young people in growth, sportsmen, pregnant ladies, elderly and sick people, and it is useful as an aid for the treatment of flu, cough and even lung issues.\n' +
            'It is also particularly appreciated in the cooking for the preparation of dishes ( especially sweets ) and it is even used sweetener for drinks, thanks to its high sweetening power.\n' +
            'Thanks to the annual transhumance of the beehives, we can collect even a crystallized wildflower honey, which comes from the flatland of Modena, where the clayish soil allows the cultivation of alfalfa, clovers, and melons. This honey remains less liquid than usual, delicate in taste, with a very fine grain.',
    },
    {
        id: "linden-honey",
        pictures: [
            ti1,
            ti2,
            ti3,
            ti4,
            ti5,
            ti6,
            ti7,
            ti8,

        ],
        title: 'Linden honey',
        description: 'This honey has a light amber color with yellow-green reflections that indicate its purity. Its taste is intense and sweet, which will leave a feeling of freshness in your mouth. In popular tradition, this type of honey is believed to have antispasmodic properties that act on the nervous system as a sedative. Due to this feature, it is recommended to conciliate sleep and reduce anxiety.',
        formats: ['250g','500g','1kg'],
        longDescription: 'Linden is one of the largest trees in the forests of temperate zones. It is present throughout the entire Italian peninsula, although nowadays it is less and less widespread to make room for crops and other invasive plants.\n' +
            'Our honey is produced during the summer period, after the transhumance of bees from the Po Valley to the pre-Alps surrounding the Como Lake. This honey has a light amber color with yellow-green reflections that indicate its purity. Its taste is intense and sweet, which will leave a feeling of freshness in your mouth. In popular tradition, this type of honey is believed to have antispasmodic properties that act on the nervous system as a sedative. Due to this feature, it is recommended to conciliate sleep and reduce anxiety. These properties are also used in physiotherapy, where this plant is used to produce herbal teas.\n' +
            'However, its main scientifically proven and recognized quality is in the respiratory field. In fact, it helps to counter cough, sore throat and cold. This honey also contains substances that protect and ensure good intestinal functioning, avoiding the occurrence of calculations and digestive disorders. It is also used as a cellular revitalizer for dry and dehydrated skin from the sun or wind.',
    },
    {
        id: "beehive-compound",
        pictures: [
            comp1,
            comp2,
        ],
        title: 'Beehive compound',
        description: 'The hive compound is suitable for growing children, for adults before or after intense physical activity or works, during seasonal changes, for those studying, for tiredness or after a period of illness.',
        formats: ['250g','500g'],
        longDescription: 'This product, also called energy compound or "bomb" is a convenient mixture of honey, propolis, pollen and royal jelly; these products, known since ancient times for their beneficial and healing properties, are very useful during periods of greater stress and psychophysical fatigue. The honey and pollen contained in this compound make the product a strong energizer and a rich source of vitamins and minerals. The royal jelly, the only food of the queen bee, is a fundamental reserve of amino acids, proteins and pantothenic acid for psychic functions while propolis is an excellent natural antibiotic against all inflammations. The hive compound is suitable for growing children, for adults before or after intense physical activity or works, during seasonal changes, for those studying, for tiredness or after a period of illness.',
    },
    {
        id: "propolis",
        pictures: [
            pr1,
            pr2,
            pr3,
            pr4,
            pr5,
            pr6,
            pr7,
        ],
        title: 'Propolis',
        description: 'Propolis is produced by the combination of substances such as resins, balms collected by bees in the buds of some plants, later enriched by salivary secretions full of enzymes, aromatic acids, wax, and pollen. It is also rich in flavonoids and polyphenols, and a high number of mineral salts including iron, calcium, copper, and manganese.',
        formats: ['20g','30g'],
        longDescription: 'it is constituted by a resinous substance that covers and protects hives from external agents such as mold, bacteria, fungi, and insects. In fact, the name itself comes from the ancient Greek ‘pro’ and ‘polis’ and literally means "in defense of the city". Bees use this substance for its biological properties ( antibacterial and antifungal ) but also to close and seal holes and cracks in the hive. Based on the observation of the use they make of it and from different experiments carried out and certified by different doctors and researchers, we can say that propolis is a real natural antibiotic for our body: it has the property of not disturbing the liver, the kidneys and does not alter the intestinal bacterial flora: id est, it has no contraindications. The circumstances in which it is discouraged the assumption of propolis are during pregnancy, lactation and in children younger than one year.\n' +
            'Propolis is produced by the combination of substances such as resins, balms collected by bees in the buds of some plants, later enriched by salivary secretions full of enzymes, aromatic acids, wax, and pollen. It is also rich in flavonoids and polyphenols, and a high number of mineral salts including iron, calcium, copper, and manganese.\n' +
            'Its components make propolis a powerful anti-inflammatory agent for the whole body. It also has antiviral, wound-healing, antifungal, immunostimulant effects. It has been used since ancient times to cure scars, burns, irritations, and pimples but also on abscesses, mouth ulcers, vesicles of the oral cavity and gingivitis. In the present, it is used more and more for sore throats and respiratory tract infections, to counteract inflammation and as a preventive against colds and flu. Propolis can also be used to disinfect skin cuts and laryngitis in dogs and cats.',
    },{
        id: "pollen",
        pictures: [
            po1,
            po2,
            po3,
            po4,
            po5,
            po6,
        ],
        title: 'Pollen',
        description: 'Pollen is one of the richest substances of nature, it contains a wide range of different elements that are essential for a balanced diet. It consists partly of royal jelly and, therefore, is richly energetic and retains all the properties of royal jelly.',
        formats: ['100g','250g'],
        longDescription: 'flower pollen is a substance collected by bees, which is then mixed with honey and royal jelly, forming the staple food for the diet of young bees. It shows up in nature as a very fine yellow powder, consisting of microscopic granules, which remains trapped in the hair that covers the bee when it settles on the flower in search of nectar. In this way the bee transports it along its way, pollinating the different plants on which it rests, and finally, once back in the beehive, it cleans itself by gathering and mixing the pollen with the nectar collected previously. This mixture is deposited in the cells and used for feeding the larvae.\n' +
            'Pollen is one of the richest substances of nature, it contains a wide range of different elements that are essential for a balanced diet. It consists partly of royal jelly and, therefore, is richly energetic and retains all the properties of royal jelly. A grain contains 21 of the 23 existing amino acids needed for the body. It also contains glucose and fructose, lipids ( most are essential fats such as omega 3 and omega 6 ), mineral salts ( such as iron, potassium, calcium and many others ), many vitamins, especially belonging to group B but also vitamin A, C, D, K, enzymes and growth hormones. It also contains acetylcholine and many other substances with antibiotic and antibacterial properties. Due to its characteristics, it is used to regulate the balance of the body and its growth. The most evident effect in humans is the increase in appetite and general metabolism. Therefore, it is used to balance the diet of underweight people or those with an organic deficit. It is recommended as a light remedy for certain pathologies that occur with a modest entity such as constipation, colitis, and intestinal infections. Another characteristic is the antianemia action, which leads to an increase in the hemoglobin production rate. Recommended for people who have a low-meat diet as a vitamin B12 supplement.\n' +
            'Pollen is sold in the form of small soft and grainy balls, with a distinctive flavor. It is excellent to take it with hot drinks after diluting it, to cover its flavor and to avoid chewing it. It can also be consumed with honey, other jams or yogurt. The recommended dose is 15-20 gr. per day for a period of about 20-30 days. The most suitable moment to consume it is in the early morning before breakfast or it is possible to split the daily dose in two: one in the morning and one in the evening before eating. It is recommended in convalescences, for anemics, for children, for the elderly, for pregnant and nursing mothers.',
    },{
        id: "royal-jelly",
        pictures: [
            pappa1,
            pappa2,
            pappa3,
            pappa4,
            pappa5,
        ],
        title: 'Royal jelly',
        description: 'It is recommended to use it to enrich the diet of elderly people, to balance an adult\'s diet and to strengthen the organism during seasonal changes. It is a precious natural supplement for children especially in cases of lack of appetite.',
        formats: ['10g'],
        longDescription: 'Is a substance of protein nature produced by nurse bees, which take care of the future queen bees. Thanks to this nutrient the larvae instead of becoming workers in 21 days with an average life of 40 days, becomes queen bees in 16 days with an average life of 3-4 years.\n' +
            'It was discovered in the middle of the seventeenth century by scientists who studied insects during their research. Only later it was understood that this substance was intended only for the queens and that only if the larvae were fed in this way, they could become queens. In Italy, production began in 1950 but then it disappeared due to the importation of the products from Asian countries. Only in recent years, this tradition has resumed. After long hours of work, the beekeeper manually removes only a few grams of royal jelly for each beehive using non-metallic tools, carefully, in order not to damage the work of bee curators in the selection and training of new queens. The raw royal jelly, in the form of solid flakes, is placed in sterile containers, filtered to remove natural impurities ( such as pollen and wax ) and then cooled in special containers to be lyophilized.\n' +
            'It is a light-yellow colored substance, with a typical acidulous-sugary taste and smell. The main elements that make it up are water, proteins, sugars, lipids and mineral salts. Proteins, considering the dry weight of royal jelly, represent about 70% of the total weight and can be found both in the form of simple amino acids and in the combined state. This substance contains all the fundamental amino acids, which can be ingested only with food, required for the well-being of the body. Royal jelly contains different lipids among which we have hydroxylated or dicarboxylic fatty acids which are commonly found in foods of vegetable and animal origin. The main salts contained are calcium, iron, magnesium, and potassium. The vitamins present in large quantities are Thiamine (B1), Riboflavin (B2), Nicotinamide (B3 or PP), Pantothenic Acid (B5), Pyridoxine (B6), Mesoisositol (B7), Biotin (Be or H), and folic acid (B9). Present in a very small amount is also Vitamin A, Vitamin C, Vitamin D, Vitamin E. another important constituent is Acetylcholine, an antibacterial and antibiotic substance, produced during the digestion of honeydew bees, nectar, and pollen.\n' +
            'It is recommended to use it to enrich the diet of elderly people, to balance an adult\'s diet and to strengthen the organism during seasonal changes. It is a precious natural supplement for children especially in cases of lack of appetite.\n' +
            'It is recommended to use it to enrich the diet of elderly people, to balance an adult diet and to strengthen the organism during seasonal changes. It is a precious natural supplement for children especially in cases of lack of appetite.\n' +
            'A daily dose of 200 mg is recommended for at least one month. It is an excellent procedure to repeat the cycle three times per year. There are no contraindications regarding association with drugs. It is a natural product that must be kept cool at about 4-8 degrees in tightly closed and dark containers.',
    },
    {
        id: "beeswax",
        pictures: [
            cera1,
        ],
        title: 'Beeswax',
        description: 'Pure beeswax is usually used to create preparations for face, body and hand creams and in general cosmetic and pharmaceutical products, but also by carpenters to polish materials and products or to create candles.',
        formats: ['1kg'],
        longDescription: 'Pure beeswax is usually used to create preparations for face, body and hand creams and in general cosmetic and pharmaceutical products, but also by carpenters to polish materials and products or to create candles. Beeswax can be further purified by heating in water. The wax is produced in the form of thin flakes by worker bees aged between 12 and 17 days through the eight glands located in the ventral part of the abdomen. The entire piece of beeswax can be easily crushed into smaller pieces with a kitchen knife.',
    },
    {
        id: "honey-with-hazelnuts",
        pictures: [
            mn1,
            mn2,
            mn3,
            mn4,
        ],
        title: 'Honey with hazelnuts',
        description: 'Considered a delicacy of colder periods, the combination of these two products has important effects on health, for instance on blood circulation.',
        formats: ['227g','423g'],
        longDescription: 'Considered a delicacy of colder periods, the combination of these two products has important effects on health. Hazelnuts are a very rich source of vitamin E, that helps protecting the skin from the harmful effects of ultraviolet rays, B vitamins, in particular B1, B2, B3, B6, B9 (folic acid), and omega-3 fatty acids that help to lower LDL cholesterol; in hazelnuts, there is a good source of manganese, which is an enzyme produced by cells for the prevention of cancers. This fruit contains phytosterols, substances useful for the prevention of cardiovascular diseases. They are also a rich source of mineral salts such as magnesium, useful to sustain the recovery of muscles after an effort.',
    },
    {
        id: "honey-with-walnuts",
        pictures: [
            noci1,
        ],
        title: 'Honey with walnuts',
        description: 'Considered a delicacy of colder periods, the combination of these two products has important effects on health, for instance on blood circulation.',
        formats: ['443g'],
        longDescription: 'Considered a delicacy of colder periods, the combination of these two products has important effects on health, for instance on blood circulation. Walnuts are well-known for their richness in mineral salts such as magnesium, calcium, and potassium. In addition, they are a source of protein, they are rich in vitamins, including vitamin E, and of omega 3 fatty acids, which favour the lowering of LDL cholesterol; moreover, walnuts contain naturally melatonin, a substance that our body needs to keep regular sleep-wake cycles.',
    },
    {
        id: "balsamel-syrup",
        pictures: [
            bal1,
            bal2,
            bal3,
            bal4,
        ],
        title: 'Balsamel syrup',
        description: 'Rich in mineral salts, this product is particularly suitable in the winter months, as it helps to both prevent and soothe the effects of seasonal illnesses such as cold and cough.',
        formats: ['260g'],
        longDescription: 'Compound made with wildflower honey, propolis, echinacea, dog rose and essential oils of eucalyptus, thyme, mint and pine, available in a convenient 260g glass jar. It has an amber and crystallized color. It is characterized by a pungent flavor, with intense aromas given by the added essential oils. Rich in mineral salts, this product is particularly suitable in the winter months, as it helps to both prevent and soothe the effects of seasonal illnesses such as cold and cough. In fact, essential oils give it antiseptic properties which help to alleviate the first forms of cold and to decongest the respiratory tract. Echinacea helps to increase the immune defenses, while the thymus helps to fight the cold. Balsamel is produced in the Cremasca pre-Alpine area and the ingredients that compose it boast particular aromas, which are enhanced by the Mediterranean climate and the unique vegetation that characterizes these places. Made with high quality ingredients, Balsamel is an effective natural remedy suitable for the whole family, to always be kept keep at home especially during the cold seasons.',
    },
    {
        id: "iceland-lichen-syrup",
        pictures: [
            lic1,
            lic2,
        ],
        title: 'Iceland lichen syrup',
        description: 'This product boasts bactericidal, antibiotic, emollient and protective properties of the respiratory and gastrointestinal system: it is in fact indicated for respiratory disorders especially in the case of oily cough.',
        formats: ['100ml'],
        longDescription: 'This syrup, enriched with propolis, dog rose, polygala, tolu balm, lemon balm and mountain pine, is available in a 100ml glass bottle. This syrup does not contain sugar but is made from honey which makes it a food rich in simple sugars and vitamins, minerals and other active ingredients. This product boasts bactericidal, antibiotic, emollient and protective properties of the respiratory and gastrointestinal system: it is in fact indicated for respiratory disorders especially in the case of oily cough. Herbal extracts have a beneficial expectorant and mucolytic, emollient and anti-inflammatory action.',
    },


];