import React from 'react';
import {MdPlace, MdNature, MdStarBorder, MdPeopleOutline} from 'react-icons/md';

const FeatureBar = props => {
    const features = [
        {
            text: 'high quality',
            icon: <MdPlace className="feature_icon" />
        },
        {
            text: "sustainability of beekeeping" ,
            icon: <MdPeopleOutline className="feature_icon" />
        },
        {
            text: "respect of biodiversity",
            icon: <MdNature className="feature_icon" />
        },
        {
            text: 'non-industrial product',
            icon: <MdStarBorder className="feature_icon" />
        },
    ]
    return (
        <div className="featureBar">
            <div className="container features">
                {
                    features.map((el, i) => (
                        <div className="feature" key={`feature-${i}`}>
                            {el.icon}
                            <span className="feature_text opSans p20">{el.text}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default FeatureBar;