import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import FeatureBar from './components/FeatureBar';
import WeAreBlock from './components/WeAreBlock';
import SkillBar from './components/SkillBar';
import Divider from './components/reusable/Divider';
import ProductsBlock from './components/ProductsBlock';
//import MiddleBanner from './components/MiddleBanner';
import { ParallaxProvider } from 'react-scroll-parallax';
import {Helmet} from "react-helmet";

const App = () => {
  const [isMobile, setMobile] = useState(false);
  useEffect(
    () => {
      if (window.innerWidth < 768) {
        setMobile(true);
      }
    },
    []
  )
  return (
    <>
      <ParallaxProvider>
        <Header mobile={isMobile} />
      </ParallaxProvider>
      <FeatureBar />
      <div className="container">
          <Helmet>
              <meta charSet="utf-8" />
              <meta name="keywords" content="products product honey bees wellnes chestnut wildflower linden honeydew cherry eucalyptus sunflower orange beehive royal-jelly beeswax homepage prodotto prodotti chi siamo chi-siamo prodotti-locali familiare produzione-familiare eco bio eco-friendly alta-qualita benessere tavola apicoltura-costenaro  vertemate-con-minoprio vertemate con minoprio costenaro apicoltura giancarlo costenaro api " />
              <meta name = "description" content="We have been producing and selling honey for decades. Quality products and 100% local. Visit our website and coome visit us!!" />
              <title>Apicoltura Costenaro</title>
          </Helmet>
        <WeAreBlock />
        <SkillBar />
      </div>
      <Divider />
      <div className="container">
        <ProductsBlock />
      </div>
      {/*<ParallaxProvider>
       <MiddleBanner mobile={isMobile} />
      </ParallaxProvider>*/}
    </>
  );
}

export default App;
