import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {myAnimate} from '../helpers/animating';
import composto_alveare_250g from '../assets/homepage_images/composto_alveare_250g.jpg'
import polline_100g from '../assets/homepage_images/polline_100g.jpg'
import miele_castagno_1kg from '../assets/homepage_images/miele_castagno_1kg.jpg'
import pappa_reale1 from '../assets/homepage_images/pappa_reale1.jpg'
import propolis_1 from '../assets/homepage_images/propolis_1.jpg'
import sciroppo_balsamel1 from '../assets/homepage_images/sciroppo_balsamel1.jpg'
import cera_api from '../assets/homepage_images/cera_api_1kg_bianco.jpg';
import miele_noci from '../assets/homepage_images/miele_con_noci_443g.jpg';
import miele_nocciole from '../assets/homepage_images/miele_con_nocciole_227g.jpg';


const ProductsBlock = props => {
    const [active, setActive] = useState(0);
    const [size, setSize] = useState(0);
    const ps = ['products', 'honey', 'beehive derivatives', 'health and wellness'].map((el, i) => {
        return (
            <p key={el} onClick={() => setActive(i)} className={`prod_nav_item ${i === active ? `active` : `inactive`}`}>{el}</p>
        );
    });
    const contRef = useRef(null);
    const blocksArray = [
        {
            title: 'Propolis',
            subtitle: '',
            image: propolis_1,
            to: '/product/propolis',
            small: false,
            ref: useRef(null),
            order: 0,
            activeTabIndexes: [0,2],
            alt_img: 'propolis',
        },
        {
            title: 'Beehive compound',
            subtitle: '',
            image: composto_alveare_250g,
            to: '/product/beehive-compound',
            small: true,
            ref: useRef(null),
            order: 1,
            activeTabIndexes: [0,2],
            alt_img:'composto-alveare',
        },
        {
            title: 'Pollen',
            subtitle: '',
            image: polline_100g,
            to: '/product/pollen',
            small: false,
            ref: useRef(null),
            order: 2,
            activeTabIndexes: [0, 2],
            alt_img: 'polline',
        },
        {
            title: 'Balsamel syrup',
            subtitle: '',
            image:sciroppo_balsamel1,
            to: '/product/balsamel-syrup',
            small: true,
            ref: useRef(null),
            order: 0,
            activeTabIndexes: [0, 3],
            alt_img: 'sciroppo-balsamel-lichene',
        },
        {
            title: 'Royal jelly',
            subtitle: '',
            image: pappa_reale1,
            to: '/product/royal-jelly',
            small: false,
            ref: useRef(null),
            order: 1,
            activeTabIndexes: [0, 2],
            alt_img:'pappa-reale',
        },
        {
            title: 'Honey',
            subtitle: '',
            image: miele_castagno_1kg,
            to: '/products/',
            small: true,
            ref: useRef(null),
            order: 2,
            activeTabIndexes: [0,1],
            alt_img:'miele'
        },
        {
            title: "Beeswax",
            subtitle: "",
            image:cera_api,
            to: '/product/beeswax',
            small: true,
            ref: useRef(null),
            order: 0,
            activeTabIndexes: [0, 3],
            alt_img: 'sciroppo-balsamel-lichene',
        },
        {
            title: 'Honey with hazelnuts',
            subtitle: '',
            image: miele_nocciole,
            to: '/product/honey-with-hazelnuts',
            small: false,
            ref: useRef(null),
            order: 1,
            activeTabIndexes: [0, 2],
            alt_img:'pappa-reale',
        },
        {
            title: 'Honey with walnuts',
            subtitle: '',
            image: miele_noci,
            to: '/product/honey-with-walnuts',
            small: true,
            ref: useRef(null),
            order: 2,
            activeTabIndexes: [0,1],
            alt_img:'miele'
        },

    ];

    const setInitialPos = (padding, blocks) => {
        const positions = blocks.map((el, i) => {
            let left = ((size/3) * el.order);
            let top = padding;
            if (i >= 3 && i < 6) {
                top += 290;
            } else if (i >= 6) {
                top =+ 290 * 2
            }
            return {left, top};
        });
        blocks.forEach((el, i) => {
            myAnimate(el.ref.current, 0, div => {
                div.style.display = `block`;
            })
            .then(div => div.style.transform = `scale(1)`)
            .catch(err => console.log(err));
            el.ref.current.style.top = `${positions[i].top}px`;
            el.ref.current.style.left = `${positions[i].left}px`;
        });
    }

    const isMobile = () => window.innerWidth <= 767;

    const mobileAnimation = () => {
        const activeBlocks = blocksArray.filter(el => el.activeTabIndexes.indexOf(active) >= 0);
        const inactiveBlocks = blocksArray.filter(el => el.activeTabIndexes.indexOf(active) < 0);
        inactiveBlocks.forEach(block => {
            myAnimate(block.ref.current, 200, el => {
                el.style.transform = 'translateY(-100px)';
                el.style.opacity = '0';
            }).then(el => el.style.display = 'none')
            .catch(err => console.log("ERROR ON MOBILE ANIMATION", err));
        })
        activeBlocks.forEach(block => {
            myAnimate(block.ref.current, 200, el => {
                el.style.display = 'block';
            }).then(el => {
                el.style.opacity = '1';
                el.style.transform = 'translateY(0)';
            })
            .catch(e => console.log("ERROR ON MOBILE ANIMATION P2", e));
        })
    }

    const setPositions = padding => {
        if (active === 0) {
            return setInitialPos(padding, blocksArray);
        } else {
            const activeBlocks = blocksArray.filter(el => el.activeTabIndexes.indexOf(active) >= 0);
            const inactiveBlocks = blocksArray.filter(el => el.activeTabIndexes.indexOf(active) < 0);
            inactiveBlocks.forEach(el => {
                myAnimate(el.ref.current, 200, div => {
                    div.style.transform = `scale(0)`;
                })
                .then(div => div.style.display=`none`)
                .catch(err => console.log(err));
            })
            let ord = 0;
            activeBlocks.forEach(el => {
                if (ord > 2) {
                    ord = 0;
                }
                el.order = ord;
                ord++;
                myAnimate(el.ref.current, 0, div => {
                    div.style.display = `block`;
                })
                .then(div => div.style.transform = `scale(1)`)
                .catch(err => console.log(err));
            });
            return setInitialPos(padding, activeBlocks);
        }
    }

    useEffect(
        () => {
            if (!isMobile())
            {
                const resizeListener = () => {
                    setSize(contRef.current.clientWidth);
                }
                window.addEventListener('resize', resizeListener, false);
                setPositions(7, blocksArray);
                setSize(contRef.current.clientWidth);
                return function restore() { 
                    window.removeEventListener('resize', resizeListener, false);
                }
            } else {
                mobileAnimation()
            }
        }, [blocksArray, setPositions, isMobile, mobileAnimation]
    );

    const mappedBlocks = blocksArray.map((el ,i) => (
        <div ref={el.ref} key={`${el.title}-${i}`} className={`prod_box_small prod_box`}>
            <img src={el.image} className="prod_box_img" alt={el.alt_img} />
            <div className="absolute_cover">
                <Link className="cover_link" to={el.to}>
                    <p className="cover_title">{el.title}</p>
                    <p className="cover_subtitle">{el.subtitle}</p>
                </Link>
            </div>
        </div>
    ));
    return (
        <div className="products_home">
            <div className="product_title_block">
                <p className="mini_title poppins">
                    OUR PRODUCTS
                </p>
                <h2>Farm to table products</h2>
            </div>
            <div className="animated_box">
                <div className="prod_nav">
                    <nav>
                        {ps}
                    </nav>
                </div>
                <div className="prod_box_cont" ref={contRef}>
                    {mappedBlocks}
                </div>
            </div>
        </div>
    );
}

export default ProductsBlock;