import React, {useState} from 'react';
import { useAlert } from "react-alert";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Loading from './../reusable/Loading';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

const captcha_public_key = "6LczzMUUAAAAABAKubidwu5k331UHiDmSWdN9719";

const ContattiForm = () => {
    const alert = useAlert();
    const [isLoading, setLoading] = useState(false);

    const handleForm = async form => {
        console.log(form);
        setLoading(true);
        const body = new FormData();
        body.append('nome', form.nome);
        body.append('email', form.email);
        body.append('oggetto', form.oggetto);
        body.append('messaggio', form.messaggio);
        body.append('recaptcha', form.recaptcha);
        const response = await fetch('https://en.apicolturacostenaro.com/mail.php', {
            method: 'POST',
            body 
        })
        .then(resp => resp.json())
        .then(resp => {
            console.log(resp);
            return resp;
        })
        .catch(err => console.log('ERROR',err));
        try {
            if (response.success) {
                alert.success(response.message);
            } else {
                alert.error('qualcosa è andato storto, riprova più tardi');
                console.log(response.message);
            }
        } catch(error) {
            alert.error('qualcosa è andato storto, riprova più tardi');
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    const initialV = {
        nome: "",
        email: "",
        oggetto: "",
        messaggio: "",
        recaptcha: "",
    };

    return (
        <GoogleReCaptchaProvider reCaptchaKey={captcha_public_key} >
            <div className="contatti_form">
                <Loading showing={isLoading} />
                <div className="simple_contacts">
                    <div className="s_contact">
                        <h6 className="s_contact_h6 poppins">Telephone:</h6>
                        <p className="s_contact_p opSans">
                            <a href="tel: +39 031 900 759">+(39) 031 900 759</a>
                        </p>
                    </div>
                    <div className="s_contact">
                    <h6 className="s_contact_h6 poppins">Email:</h6>
                        <p className="s_contact_p opSans">
                            <a href="mailto: info@apicolturacostenaro.com">info@apicolturacostenaro.com</a>
                        </p>
                    </div>
                    <div className="s_contact">
                    <h6 className="s_contact_h6 poppins">Address:</h6>
                        <p className="s_contact_p opSans">
                            <a href="https://www.google.com/maps/place/Apicoltura+Costenaro+G+%26+C+Snc/@45.7244219,9.0710193,17z/data=!3m1!4b1!4m5!3m4!1s0x47869a25f55cc271:0x3748496ac629dbb7!8m2!3d45.7244219!4d9.073208?hl=it">Via Monte Rosa 3, Vertemate con Minoprio, <br /> 22070 Como</a>
                        </p>
                    </div>
                </div>          
                <div className="form">
                    <Formik
                        initialValues={initialV}
                        validationSchema={
                            yup.object().shape({
                                nome: yup
                                    .string()
                                    .required('il nome è un campo obbligatorio'),
                                email: yup
                                    .string()
                                    .required(`l'email è un campo obbligatorio`)
                                    .email(`inserire un'email valida`),
                                oggetto: yup
                                    .string()
                                    .required(`l'oggetto è un campo obbligatorio`),
                                messaggio: yup
                                    .string()
                                    .trim()
                                    .required(`inserire un messaggio`), 
                            })
                        }
                        onSubmit={(values, {resetForm}) => {
                            handleForm(values)
                            resetForm();
                        }}
                    >
                        {
                            ({ errors, touched, values, handleChange, setFieldTouched, setFieldValue, isValid}) => (
                                <Form>
                                    <div className="two_inps">
                                        <Field type="text" name="nome" placeholder="Name" />
                                        <Field type="email" name="email" placeholder="Email" />
                                    </div>
                                    { errors.nome && touched.nome && <span className="errors"> {errors.nome} </span> }
                                    { errors.email && touched.email && <span className="errors"> {errors.email} </span> }
                                    <div className="single_input">
                                        <Field type="text" name="oggetto" placeholder="Object" />
                                        { errors.oggetto && touched.oggetto && <span className="errors"> {errors.oggetto} </span> }
                                    </div>
                                    <div className="text_area">
                                        <textarea rows="8" name="messaggio" value={values.messaggio} onChange={handleChange} placeholder="Message" onBlur={() => setFieldTouched('messaggio')}></textarea>
                                        { errors.messaggio && touched.messaggio && <span className="errors"> {errors.messaggio} </span> }
                                    </div>
                                    <GoogleReCaptcha onVerify={token => setFieldValue('recaptcha', token)} />
                                    <button
                                        type="submit"
                                        className="submit_btn"
                                        disabled={!isValid}
                                    >
                                        Send message
                                    </button>
                                    <div style={{clear: 'both'}}></div>
                                </Form>
                            )
                        }
                    </Formik>
                </div>

            </div>
        </GoogleReCaptchaProvider>
    );
}

export default ContattiForm;