import React, { useEffect } from 'react';
import { singles } from '../prodotti/prod_data';
import ProductSlider from './ProductSlider';
import {Helmet} from "react-helmet";

const Prodotto = props => {
    useEffect(
        () => document.documentElement.scrollIntoView(),
        []
    );
    const product = singles.find(el => el.id === props.match.params.id);
    return (
        <div className="prodotto">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="miele miele-di-acacia miele-di-castagno miele-di-eucalipto miele-di-tiglio miele-naturale miele-bio miele-buono miele-economico miele-fresco miele-basso-costo miele-qualita propoli pappa-reale-bio dop doc  apicoltura-costenaro  vertemate-con-minoprio vertemate con minoprio costenaro apicoltura giancarlo costenaro api " />
                <meta name = "description" content="We have been producing and selling honey for decades. Quality products and 100% local." />
                <title>Products | {product.title} | Apicoltura Costenaro</title>
            </Helmet>
            <div className="container">
                <div className="product_main_container">
                    <ProductSlider pictures={product.pictures} />
                    <div className="specific_product_text">
                        <div className="product_title">
                            <h1 className='poppins'>{product.title}</h1>
                        </div>
                        <div className="product_description">
                            <p className="opSans">
                                {product.description}
                            </p>
                        </div>
                        <div className="formats">
                            {
                                product.formats.map((el, i) => (
                                    <div className="format" key={`format-${i}`}>
                                        <span className="opSans">
                                            {el}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="long_description">
                    <h2 className="poppins" style={{marginTop: '0'}}>Description</h2>
                    <p className="opSans">
                        {product.longDescription}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Prodotto;